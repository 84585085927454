<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "FormTemplates.EditFormTemplate",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        v-if="detailRequestEnd && formTemplateData.isOk"
        @methodSave="onSubmit('btn-save')"
        :isNewRecord="false"
      />
    </div>
  </div>
  <SetLoader v-if="!detailRequestEnd" />
  <div v-if="errors.length > 0" class="alert alert-warning">
    <ul class="mb-0">
      <li v-for="error in errors" v-bind:key="error">
        {{ error }}
      </li>
    </ul>
  </div>
  <form
    v-show="detailRequestEnd && formTemplateData.isOk"
    class="form-formtemplate-new pe-2 ps-2"
    novalidate
  >
    <div class="row row-cols-1">
      <div class="col col-md-6">
        <label for="Name" class="form-label required"
          >{{
            $t(
              "FormTemplates.Name",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Name"
          v-model="formTemplateData.name"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-6">
        <label for="FormulaName" class="form-label required">{{
          $t(
            "FormTemplates.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          type="text"
          class="form-control"
          id="FormulaName"
          :disabled="true"
          v-model="formTemplateData.formulaName"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-6">
        <label for="OutputType" class="form-label required">{{
          $t(
            "FormTemplates.OutputType",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          id="OutputType"
          type="SelectWithLocalData"
          :selectedData="selectedOutputType"
          :allowEmpty="false"
          :data="outputTypeData"
          @onChange="onChangeForOutputType"
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-12">
        <label for="Description" class="form-label">{{
          $t(
            "FormTemplates.Description",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <textarea
          name="Description"
          class="form-control"
          id="Description"
          rows="5"
          v-model="formTemplateData.description"
          :spellcheck="this.$isTextSpellCheck"
        ></textarea>
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-6">
        <label for="ReportType" class="form-label required">{{
          $t(
            "FormTemplates.ReportType",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          id="ReportType"
          type="SelectWithLocalData"
          :selectedData="selectedReportType"
          :allowEmpty="false"
          :data="reportTypeData"
          @onChange="onChangeForReportType"
        />
      </div>
      <div v-if="whichReport == 3" class="col col-md-6">
        <label for="DashboardReport" class="form-label required">{{
          $t(
            "FormTemplates.DashboardReports",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeDashboardReports"
          :allowEmpty="false"
          :isParameters="true"
          :isDetailIconShow="true"
          :routeLink="String.format('#/CustomObject/Detail/')"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Brs-DashboardReports?coId={0}',
              this.$route.params.customObjectId
            )
          "
          :selectedData="selectedDashboardReport"
        />
      </div>
      <div v-if="whichReport == 2" class="col col-md-6">
        <label for="XtraReports" class="form-label required">{{
          $t(
            "FormTemplates.XtraReports",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeXtraReports"
          :allowEmpty="false"
          :isParameters="true"
          :isDetailIconShow="true"
          :routeLink="String.format('#/CustomObject/Detail/')"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Brs-XtraReports?coId={0}',
              this.$route.params.customObjectId
            )
          "
          :selectedData="selectedXtraReport"
        />
      </div>
      <div v-if="whichReport != 1" class="col-md-6 mt-2">
        <label for="firstParameter" class="form-label required"
          >{{
            $t(
              "FormTemplates.FirstParameter",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeFirstParameter"
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFieldsWithFormula?customObjectFormulaName={0}',
              this.formTemplateData.customObjectFormulaName
            )
          "
          :selectedData="selectedFirstParameter"
        />
      </div>
      <div v-if="whichReport != 1" class="col-md-6 mt-2">
        <label for="secondParameter" class="form-label"
          >{{
            $t(
              "FormTemplates.SecondParameter",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeSecondParameter"
          :allowEmpty="true"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFieldsWithFormula?customObjectFormulaName={0}',
              this.formTemplateData.customObjectFormulaName
            )
          "
          :selectedData="selectedSecondParameter"
        />
      </div>
    </div>
    <div v-if="whichReport == 1" class="row row-cols-1 mt-3">
      <div class="col col-md-12">
        <label for="File" class="form-label required">{{
          $t("FormTemplates.File", {}, { locale: this.$store.state.activeLang })
        }}</label>
        <div>
          <div
            v-if="!this.isDownloader"
            class="p-4 bg-gray-100 file-drag-and-drop-block"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
          >
            <input
              type="file"
              id="File"
              @change="onFileSelected"
              ref="fileInput"
              class="hidden"
              accept=".docx"
              required
            />

            <label for="File" class="block cursor-pointer w-100 fs-5">
              <div class="text-center fw-bold">
                {{
                  $t(
                    "FormTemplates.BrowseOrDragandDrop",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </div>
            </label>
            <ul
              class="list-group mt-3 list-group-numbered"
              v-if="this.selectedFiles.length"
            >
              <li
                class="list-group-item"
                v-for="file in selectedFiles"
                :key="file"
              >
                {{ file.name }}
                <span class="badge badge-primary"> {{ file.size }} kb</span
                ><button
                  class="btn btn-danger btn-sm btn-no-custom-style float-end"
                  type="button"
                  @click="remove(selectedFiles.indexOf(file))"
                >
                  <i class="bi bi-trash cursor-pointer"></i>
                </button>
              </li>
            </ul>
          </div>
          <div v-else>
            <label @click="getFormTemplate()" class="underline text-primary"
              ><i style="margin-right: 5px" class="bi bi-download"></i
              >{{ formTemplateData.fileName }}</label
            >
            <a
              @click="closeDownloadTag()"
              style="font-size: 20px"
              class="cursor-pointer align-middle"
            >
              <i class="bi bi-x text-danger"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="whichReport == 1" class="row row-cols-1 mt-3">
      <div class="col-md-6">
        <div class="form-check">
          <label for="IsGetFromFilenameFields" class="form-label">{{
            $t(
              "FormTemplates.IsGetFromFilenameFields",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <input
            class="form-check-input"
            type="checkbox"
            id="IsGetFromFilenameFields"
            v-model="formTemplateData.isGetFromFilenameFields"
            @click="
              isCheckForIsGetFromFilenameFields(
                formTemplateData.isGetFromFilenameFields
              )
            "
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-check">
          <label for="IsSetEmptyIfValueNotFound" class="form-label">{{
            $t(
              "FormTemplates.IsSetEmptyIfValueNotFound",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <input
            class="form-check-input"
            type="checkbox"
            id="IsSetEmptyIfValueNotFound"
            v-model="formTemplateData.isSetEmptyIfValueNotFound"
          />
        </div>
      </div>
      <div v-if="this.isFileNameFieldNames" class="col-md-12">
        <label for="FileNameFieldNames" class="form-label">{{
          $t(
            "FormTemplates.FileNameFieldNames",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onMultipleSelectChange="onChangeFileNameFieldNames"
          :allowEmpty="true"
          :isParameters="true"
          :isMultiple="true"
          :selectedData="selectedFileNameFieldNames"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFields?coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col-md-6">
        <div class="form-check">
          <label for="IsActive" class="form-label">{{
            $t(
              "FormTemplates.IsActive",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <input
            class="form-check-input"
            type="checkbox"
            id="IsActive"
            v-model="formTemplateData.isActive"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-check">
          <label
            for="IsPermissionApply"
            class="form-label"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :title="
              this.$t(
                'FormTemplates.ValidationTooltip',
                {},
                { locale: this.$store.state.activeLang }
              )
            "
            >{{
              $t(
                "FormTemplates.IsValidationApply",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label
          >
          <input
            class="form-check-input"
            type="checkbox"
            id="IsPermissionApply"
            v-model="formTemplateData.isPermissionApply"
            @click="
              isCheckIsPermissionApply(formTemplateData.isPermissionApply)
            "
          />
        </div>
      </div>
      <div v-if="whichReport != 1" class="col-md-6">
        <div class="form-check">
          <label for="IsPreviewEnabled" class="form-label">{{
            $t(
              "FormTemplates.IsPreviewEnabled",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <input
            class="form-check-input"
            type="checkbox"
            id="IsPreviewEnabled"
            v-model="formTemplateData.isPreviewEnabled"
          />
        </div>
      </div>
      <div v-if="this.isFormula" class="col col-md-6">
        <label for="Validation" class="form-label required">{{
          $t(
            "FormTemplates.Validation",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          :allowEmpty="false"
          :resetSelect="selectsReset"
          :selectedData="selectedValidation"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryComponentValidations?customObjectId={0}',
              this.$route.params.customObjectId
            )
          "
          @onChange="onChangeValidation"
        />
      </div>
    </div>

    <ActionButtons
      v-if="$isMobile()"
      classes="mt-3"
      @methodSave="onSubmit('btn-save')"
      :isNewRecord="false"
    />
  </form>
</template>
<script>
import $ from "jquery";
import { createToast } from "mosha-vue-toastify";
export default {
  name: "CustomObjectEditFormTemplate",
  components: {},
  data() {
    return {
      detailRequestEnd: false,
      selectedFiles: [],
      selectedValidation: [],
      selectedFirstParameter: [],
      selectedSecondParameter: [],
      notUploadedFiles: [],
      errors: [],
      buttonDisabled: false,
      isFileNameFieldNames: false,
      isFormula: false,
      whichReport: 1,
      formTemplateData: {},
      outputTypeData: [
        {
          key: 1,
          value: this.$t(
            "ReportExportTypes.Word",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "ReportExportTypes.Pdf",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedOutputType: [],
      selectedXtraReport: [],
      selectedDashboardReport: [],
      reportTypeData: [
        {
          key: 1,
          value: this.$t(
            "ReportTypes.WordTemplate",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "ReportTypes.XtraReport",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "ReportTypes.Dashboard",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedReportType: [],
      selectedFileNameFieldNames: [],
      isDownloader: false,
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
    };
  },
  methods: {
    onChangeForOutputType: function (selected) {
      this.formTemplateData.outputType = selected.key;
    },
    onChangeForReportType: function (selected) {
      this.formTemplateData.reportTypeId = selected.key;
      this.formTemplateData.reportType = selected.key;
      this.formTemplateData.reportTypeName = selected.value;
      this.whichReport = 1;
      if (selected.key == 3) {
        this.whichReport = 3;
        this.formTemplateData.xtraReportPublicId = "";
        this.formTemplateData.xtraReportName = "";
        this.formTemplateData.FileNameFieldPublicIds = "";
        this.formTemplateData.isGetFromFilenameFields = false;
        this.formTemplateData.isSetEmptyIfValueNotFound = false;
        this.formTemplateData.fileName = "";
        this.formTemplateData.file = null;
        this.selectedFiles = [];
        this.formTemplateData.filePath = "";
      } else if (selected.key == 2) {
        this.whichReport = 2;
        this.formTemplateData.dashboardPublicId = "";
        this.formTemplateData.dashboardName = "";
        this.formTemplateData.FileNameFieldPublicIds = "";
        this.formTemplateData.isGetFromFilenameFields = false;
        this.formTemplateData.isSetEmptyIfValueNotFound = false;
        this.formTemplateData.fileName = "";
        this.formTemplateData.file = null;
        this.selectedFiles = [];
        this.formTemplateData.filePath = "";
      } else if (selected.key == 1) {
        this.formTemplateData.xtraReportPublicId = "";
        this.formTemplateData.xtraReportName = "";
        this.formTemplateData.dashboardPublicId = "";
        this.formTemplateData.dashboardName = "";
        this.formTemplateData.firstParameterName = "";
        this.formTemplateData.firstParameterFormulaName = "";
        this.formTemplateData.firstParameterPublicId = "";
        this.formTemplateData.secondParameterName = "";
        this.formTemplateData.secondParameterFormulaName = "";
        this.formTemplateData.secondParameterPublicId = "";
        this.selectedFirstParameter = [];
        this.selectedSecondParameter = [];
      }
    },
    onChangeDashboardReports: function (selected) {
      this.formTemplateData.dashboardPublicId = selected.key;
      this.formTemplateData.dashboardName = selected.value;
    },
    onChangeXtraReports: function (selected) {
      this.formTemplateData.xtraReportPublicId = selected.key;
      this.formTemplateData.xtraReportName = selected.value;
    },
    onChangeFirstParameter: function (selected) {
      this.formTemplateData.firstParameterPublicId = selected.publicId;
      this.formTemplateData.firstParameterFormulaName = selected.key;
      this.formTemplateData.firstParameterName = selected.value;
    },
    onChangeSecondParameter: function (selected) {
      this.formTemplateData.secondParameterPublicId = selected.publicId;
      this.formTemplateData.secondParameterFormulaName = selected.key;
      this.formTemplateData.secondParameterName = selected.value;
    },
    onChangeFileNameFieldNames(ids) {
      this.formTemplateData.fileNameFieldPublicIds = ids;
    },
    onChangeValidation: function (selected) {
      this.formTemplateData.validationPublicId = selected.key;
      this.formTemplateData.validationName = selected.value;
    },
    isCheckForIsGetFromFilenameFields(selected) {
      if (selected == false) {
        this.isFileNameFieldNames = true;
      } else {
        this.isFileNameFieldNames = false;
        this.formTemplateData.FileNameFieldPublicIds = "";
        this.formTemplateData.FileNameFieldNames = "";
      }
    },
    isCheckIsPermissionApply(selected) {
      if (selected == false) {
        this.isFormula = true;
      } else {
        this.isFormula = false;
        this.formTemplateData.formula = "";
        this.model.validationName = "";
        this.model.validationPublicId = "";
      }
    },
    onFileSelected() {
      this.selectedFiles = [...this.$refs.fileInput.files];
      if (!this.extensionControl(this.selectedFiles[0].name)) {
        return;
      }
      this.errors = [];
      this.formTemplateData.file = this.selectedFiles[0];
      this.formTemplateData.fileName = this.selectedFiles[0].name;
      this.formTemplateData.filePath =
        "." + this.selectedFiles[0].name.split(".").pop();
    },
    dragAndDropOnChange() {
      this.selectedFiles = [...this.$refs.fileInput.files];
      this.formTemplateData.file = this.selectedFiles[0];
      this.formTemplateData.fileName = this.selectedFiles[0].name;
      this.formTemplateData.filePath =
        "." + this.selectedFiles[0].name.split(".").pop();
    },
    remove(i) {
      this.selectedFiles.splice(i, 1);
      this.formTemplateData.file = null;
      this.formTemplateData.fileName = "";
      this.formTemplateData.filePath = "";
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.fileInput.files = event.dataTransfer.files;
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
      if (!this.extensionControl(event.dataTransfer.files[0].name)) {
        return;
      }
      this.dragAndDropOnChange(); // Trigger the onChange event manually
    },
    extensionControl(name) {
      if (!name.includes(".docx")) {
        createToast(
          this.$t(
            "FieldErrors.UploadFileNotSupportedForWord",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: "true",
            position: "top-right",
            type: "warning",
            transition: "zoom",
          }
        );
        this.selectedFiles = [];
        this.$refs.fileInput.files = "";
        return false;
      }
      return true;
    },
    getFormTemplate() {
      var id = this.$route.params.formTemplateId;
      var coid = this.$route.params.customObjectId;

      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-FormTemplateGetTemplate?id={0}&coid={1}",
            id,
            coid
          ),
          { responseType: "blob" }
        )
        .then((response) => {
          const blob = new Blob([response.data], {
            type: String.format("application/docx"),
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = this.formTemplateData.fileName;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onSubmit(buttonName) {
      var form = $(".form-formtemplate-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var formData = new FormData();
      formData.append(
        "CustomObjectPublicId",
        this.formTemplateData.customObjectPublicId
      );
      formData.append("PublicId", this.$route.params.formTemplateId);
      //formData.append("FieldPublicId", self.field.publicId);
      formData.append("FilePath", this.formTemplateData.filePath);
      if (!String.isNullOrWhiteSpace(this.formTemplateData.fileName)) {
        formData.append("FileName", this.formTemplateData.fileName);
      }
      if (!String.isNullOrWhiteSpace(this.formTemplateData.file)) {
        formData.append("File", this.formTemplateData.file);
      }
      if (!String.isNullOrWhiteSpace(this.formTemplateData.description)) {
        formData.append("Description", this.formTemplateData.description);
      }
      if (
        !String.isNullOrWhiteSpace(this.formTemplateData.fileNameFieldPublicIds)
      ) {
        formData.append(
          "FileNameFieldPublicIds",
          this.formTemplateData.fileNameFieldPublicIds
        );
      }
      if (
        !String.isNullOrWhiteSpace(this.formTemplateData.FileNameFieldNames)
      ) {
        formData.append(
          "FileNameFieldNames",
          this.formTemplateData.FileNameFieldNames
        );
      }
      if (!String.isNullOrWhiteSpace(this.formTemplateData.formula)) {
        formData.append("Formula", this.formTemplateData.formula);
      }
      if (!String.isNullOrWhiteSpace(this.formTemplateData.dashboardPublicId)) {
        formData.append(
          "DashboardPublicId",
          this.formTemplateData.dashboardPublicId
        );
        formData.append("DashboardName", this.formTemplateData.dashboardName);
      }
      if (
        !String.isNullOrWhiteSpace(this.formTemplateData.xtraReportPublicId)
      ) {
        formData.append(
          "XtraReportPublicId",
          this.formTemplateData.xtraReportPublicId
        );
        formData.append("XtraReportName", this.formTemplateData.xtraReportName);
      }
      if (
        !String.isNullOrWhiteSpace(this.formTemplateData.validationPublicId)
      ) {
        formData.append(
          "ValidationPublicId",
          this.formTemplateData.validationPublicId
        );
        formData.append("ValidationName", this.formTemplateData.validationName);
      }
      if (
        !String.isNullOrWhiteSpace(this.formTemplateData.firstParameterPublicId)
      ) {
        formData.append(
          "FirstParameterName",
          this.formTemplateData.firstParameterName
        );
        formData.append(
          "FirstParameterPublicId",
          this.formTemplateData.firstParameterPublicId
        );
        formData.append(
          "FirstParameterFormulaName",
          this.formTemplateData.firstParameterFormulaName
        );
      }
      if (
        !String.isNullOrWhiteSpace(
          this.formTemplateData.secondParameterPublicId
        )
      ) {
        formData.append(
          "SecondParameterName",
          this.formTemplateData.secondParameterName
        );
        formData.append(
          "SecondParameterPublicId",
          this.formTemplateData.secondParameterPublicId
        );
        formData.append(
          "SecondParameterFormulaName",
          this.formTemplateData.secondParameterFormulaName
        );
      }
      formData.append("Name", this.formTemplateData.name);
      formData.append("IsActive", this.formTemplateData.isActive);
      formData.append("OutputType", this.formTemplateData.outputType);
      formData.append(
        "IsSetEmptyIfValueNotFound",
        this.formTemplateData.isSetEmptyIfValueNotFound
      );
      formData.append(
        "IsGetFromFilenameFields",
        this.formTemplateData.isGetFromFilenameFields
      );
      formData.append(
        "IsPermissionApply",
        this.formTemplateData.isPermissionApply
      );
      formData.append("DisplayOrder", 1);

      formData.append("ReportType", this.formTemplateData.reportType);
      formData.append("ReportTypeId", this.formTemplateData.reportTypeId);

      formData.append(
        "IsPreviewEnabled",
        this.formTemplateData.isPreviewEnabled
      );

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);
      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-FormTemplateEdit", formData)
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push(
              "/CustomObject/FormTemplates/" + this.$route.params.customObjectId
            );
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
        });
    },
    getFormTemplateData() {
      var id = this.$route.params.formTemplateId;
      var coid = this.$route.params.customObjectId;
      this.errors = [];
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-FormTemplateGet?id={0}&coid={1}", id, coid))
        .then((response) => {
          this.formTemplateData = response.data;
          this.detailRequestEnd = true;
          if (response.data.isOk) {
            if (!String.isNullOrWhiteSpace(response.data.description)) {
              this.formTemplateData.description = response.data.description;
            }
            if (!String.isNullOrWhiteSpace(this.formTemplateData.fileName)) {
              this.isDownloader = true;
            } else {
              this.isDownloader = false;
            }

            var selectedOutputType = this.outputTypeData.find(
              (x) => x.key == response.data.outputType
            );
            if (!String.isNullOrWhiteSpace(selectedOutputType)) {
              this.selectedOutputType.push(selectedOutputType);
            }
            var selectedReportType = this.reportTypeData.find(
              (x) => x.key == response.data.reportType
            );
            if (!String.isNullOrWhiteSpace(selectedReportType)) {
              this.selectedReportType.push(selectedReportType);
            }
            if (
              !String.isNullOrWhiteSpace(
                this.formTemplateData.dashboardPublicId
              ) &&
              !String.isNullOrWhiteSpace(this.formTemplateData.dashboardName)
            ) {
              this.selectedDashboardReport.push({
                key: this.formTemplateData.dashboardPublicId,
                value: this.formTemplateData.dashboardName,
              });
              this.whichReport = 3;
            }
            if (
              !String.isNullOrWhiteSpace(
                this.formTemplateData.xtraReportPublicId
              ) &&
              !String.isNullOrWhiteSpace(this.formTemplateData.xtraReportName)
            ) {
              this.selectedXtraReport.push({
                key: this.formTemplateData.xtraReportPublicId,
                value: this.formTemplateData.xtraReportName,
              });
              this.whichReport = 2;
            }
            if (this.formTemplateData.isPermissionApply == true) {
              var val = false;
              this.isCheckIsPermissionApply(val);
              this.selectedValidation.push({
                key: this.formTemplateData.validationPublicId,
                value: this.formTemplateData.validationName,
              });
            }
            if (this.formTemplateData.isGetFromFilenameFields == true) {
              var value = false;
              this.isCheckForIsGetFromFilenameFields(value);

              if (
                !String.isNullOrWhiteSpace(
                  this.formTemplateData.fileNameFieldPublicIds
                ) &&
                !String.isNullOrWhiteSpace(
                  this.formTemplateData.fileNameFieldNames
                )
              ) {
                var listFileNameFieldPublicIds = [];
                var listFileNameFieldNames = [];
                if (
                  this.formTemplateData.fileNameFieldPublicIds.includes(
                    this.$systemSeparator
                  )
                ) {
                  listFileNameFieldPublicIds =
                    this.formTemplateData.fileNameFieldPublicIds.split(
                      this.$systemSeparator
                    );
                  listFileNameFieldNames =
                    this.formTemplateData.fileNameFieldNames.split(
                      this.$systemSeparator
                    );
                } else {
                  listFileNameFieldPublicIds.push(
                    this.formTemplateData.fileNameFieldPublicIds
                  );
                  listFileNameFieldNames.push(
                    this.formTemplateData.fileNameFieldNames
                  );
                }
                var list = this.selectedFileNameFieldNames;
                if (listFileNameFieldPublicIds.length > 0) {
                  $.each(listFileNameFieldPublicIds, function (i, v) {
                    list.push({
                      key: v,
                      value: listFileNameFieldNames[i],
                    });
                  });
                  this.formTemplateData.fileNameFieldPublicIds =
                    listFileNameFieldPublicIds.join(this.$systemSeparator);
                }
                this.selectedFileNameFieldNames = list;
              }
            }
            if (
              !String.isNullOrWhiteSpace(
                this.formTemplateData.firstParameterFormulaName
              ) &&
              !String.isNullOrWhiteSpace(
                this.formTemplateData.firstParameterName
              )
            ) {
              this.selectedFirstParameter.push({
                key: this.formTemplateData.firstParameterFormulaName,
                value: this.formTemplateData.firstParameterName,
              });
            }
            if (
              !String.isNullOrWhiteSpace(
                this.formTemplateData.secondParameterFormulaName
              ) &&
              !String.isNullOrWhiteSpace(
                this.formTemplateData.secondParameterName
              )
            ) {
              this.selectedSecondParameter.push({
                key: this.formTemplateData.secondParameterFormulaName,
                value: this.formTemplateData.secondParameterName,
              });
            }

            this.formTemplateData.customObjectFormulaName =
              this.$store.getters._customObjectDto.formulaName;
          } else {
            this.errors.push(response.data.msg);
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    closeDownloadTag() {
      this.isDownloader = false;
      this.formTemplateData.file = "";
      this.formTemplateData.fileName = "";
      this.formTemplateData.filePath = "";
    },
  },
  mounted() {
    this.getFormTemplateData();
  },
};
</script>
<style scoped>
.underline:hover {
  text-decoration-line: underline;
  cursor: pointer;
}
</style>
